.step-container {
    flex-grow: 1;
    overflow: auto;
    background-size: cover;
}

.step-overlay {
    background-color: #191919c2;
    min-height: 100%;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    flex-direction: column;
}

.step-overlay .row {
    flex-grow: 1;
}

// This is not ideal - it adjusts margin that bootstrap overrides from MUI
.MuiTablePagination-toolbar {
    .MuiTablePagination-displayedRows  {
        margin-top: 1rem;
    }

    .MuiTablePagination-selectLabel {
        margin-top: 1rem;
    }
}

.selectable-section {
    height: 100%;
}

.selectable-section:hover {
    background-color: #ffffff55;
    cursor: pointer;
}

.selected-segment {
    background-color: #eeeeee55;
}

.zone-selector-svg path:hover {
    fill: #eeeeee !important;
    fill-opacity: 0.5 !important;
    cursor: pointer;
}

.zone-selector-svg rect:hover {
    fill: #eeeeee !important;
    fill-opacity: 0.5 !important;
    cursor: pointer;
}

#svg-tooltip {
    background-color: rgba(97, 97, 97, 0.92);
    border-radius: 4px;
    color: #fff;
    font-family: Poppins,sans-serif;
    padding: 4px 8px;
    font-size: 0.6875rem;
    max-width: 300px;
    margin: 0;
    word-wrap: break-word;
    font-weight: 500;
    position: relative;
  }
  