@import "./theme.scss";
@import "./header.scss";
@import "./map.scss";
@import "./step.scss";

// Global styles here...
html, body, #root, .App, .main-container {
    height: 100%;
    font-family: $primary-font;
    color: $text-color-primary;
    background-color: $background-color-primary;
}

.main-container {
    display: flex;
    flex-direction: column;
}
