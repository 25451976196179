.top-header {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1.8vw;
    padding-bottom: 1.8vw;
}

.header-logo {
    max-height: 80px;
}

.nav-link {
    color: white !important;
    font-weight: 200;
    font-size: 16px !important;
    padding: 0px !important;
}

.nav-item {
    margin-left: 26.8667px;
}
