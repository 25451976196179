.map-container, .leaflet-container {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}

.coordinates-display {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    font-size: 12px;
    background: white;
    color: $background-color-primary; 
    padding: 3px 6px;
    margin: 6px;
    border-radius: 5px;
}
